






































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { videoPlayer } from 'vue-video-player'
import Modal from '@/components/Modal.vue'

@Component({
    name: 'ModalVideo',
    components: {
      videoPlayer,
      Modal
    }
})
export default class extends Vue {
    isShow = false
    videoSrc = ""

    show(videoSrc: string) {
      this.isShow = true
      this.videoSrc = videoSrc
    }
  
    onCloseClicked() {
      this.videoSrc = ""
      this.isShow = false
    }
}
