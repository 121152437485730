




















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { IFeedbackData } from '../../api/types'

@Component({
    name: 'Feedback'
})
export default class extends Vue {
    @Prop({required: true}) private feedbackData?: IFeedbackData
}

