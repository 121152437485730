



































import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component({
    name: 'About'
})
export default class extends Vue {
}
