












import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import Intro from './intro/Intro.vue'
import Games from './games/Games.vue'
import About from './about/About.vue'
import Contact from './contact/Contact.vue'
import Feedbacks from './feedbacks/Feedbacks.vue'

@Component({
    name: 'Home',
    components: {
      Intro,
      Games,
      About,
      Contact,
      Feedbacks
    }
})
export default class extends Vue {
    
}
