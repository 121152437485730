













































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import {IGameData} from '@/api/types'

@Component({
    name: 'Game'
})
export default class extends Vue {
    @Prop({required: true}) private gameData?: IGameData 

    get isIosAvailable(): boolean {
        return (this.gameData && this.gameData.iosUrl != null) || false
    }
    get isAndroidAvailable(): boolean {
        return (this.gameData && this.gameData.androidUrl != null) || false
    }

    onPlayVideoClicked() {
      this.$emit('onPlayVideoClicked', this.gameData)
    }
}
